$toasterWidth: min(calc(100% - 48px), 400px);

.toaster {
  z-index: 100;
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
  position: fixed;
  bottom: 0;
  right: calc(50% - $toasterWidth / 2 - 24px);
  margin: 24px;
  width: $toasterWidth;
  box-shadow: var(--joy-shadow-lg);
  border-radius: var(--joy-radius-lg);
}

.toast {
  display: flex;
  align-items: flex-start;
  width: 100%;

  .toast__header {
    font-weight: 600;
    filter: brightness(0.7);
  }
}
