:root {
  // Colors extracted from the site logo
  --color-brand-light: #6eaad9;
  --color-brand: #375267;
  --color-brand-dark: #2f4557;
}

#root {
  min-width: 100vw;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  // Attempt to disable pull-to-refresh behavior on mobile devices.
  // See https://stackoverflow.com/questions/29008194/disabling-androids-chrome-pull-down-to-refresh-feature
  overscroll-behavior: contain;
}
