.table {
  margin: auto;

  .table__header {
    z-index: 20;
    position: sticky;
    top: 0;
    background-color: var(--joy-palette-primary-800);

    // Style header cells
    th {
      color: white;
      background-color: var(--joy-palette-primary-800);
      min-width: max-content;
      padding: 2px 8px;

      // Add white border between header cells
      &:not(:last-child) {
        border-right: 2px solid var(--joy-palette-primary-600);
      }
      &:first-child {
        border-right: none;
      }
      &:nth-child(2) {
        border-left: 2px solid var(--joy-palette-primary-600);
      }
    }
  }

  // Stick the player column to the left side of the screen.
  .table__player-col {
    z-index: 10;
    position: sticky;
    left: 0;
  }

  tbody {
    // Style non-header player cells
    .table__player-col {
      cursor: pointer;
      transition: filter 150ms;
      border-left: 2px solid var(--joy-palette-primary-800);

      &:hover {
        filter: brightness(0.85);
      }

      &:active {
        filter: brightness(0.7);
      }

      &.--knocked-out {
        background-color: #fac99e;
      }

      &:not(.--knocked-out) {
        background-color: #9edbfa;
      }
    }

    // Style every other player cell
    tr:nth-child(even) .table__player-col {
      &.--knocked-out {
        background-color: #deaa7c;
      }

      &:not(.--knocked-out) {
        background-color: #7cbede;
      }
    }

    // Style all non-header cells
    td {
      color: black;
      background-color: white;
      font-family: monospace;
      min-width: max-content;
      height: 32px;
      padding: 2px 6px;
      border-bottom: 2px solid var(--joy-palette-primary-800);
      border-right: 2px solid var(--joy-palette-primary-800);
      &:first-child {
        border-left: 2px solid var(--joy-palette-primary-800);
        border-right: none;
      }
    }

    // Style every other non-header cell
    tr:nth-child(even) td {
      background-color: var(--joy-palette-neutral-200);
    }

    // Empty last row
    tr:last-child.table__last-row td {
      background-color: var(--joy-palette-primary-800);
    }
  }
}

@media (max-width: 400px) {
  .table {
    font-size: 0.8rem;
  }
  .table td {
    height: 28px;
  }
}
