@keyframes slide {
  from {
    background-position: right, center;
  }
}

.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.home__content {
  flex: 1;
  width: min(1024px, 100%);
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--joy-shadow-md);
  overflow: auto;

  &.--scores {
    width: max-content;
  }
}
.home__controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(340px, 100%);
  padding: 16px;
}

.home__week-input {
  width: 100%;
  height: 56px;
}

.home__file-input {
  display: none;
}

.home .home__button {
  width: 100%;
  min-width: min-content;
  transition-property: height, min-height, opacity, color, background-color;
  transition-duration: 300ms;
  transition-timing-function: ease;
  height: 56px;
  min-height: 56px;
  margin-top: 12px;
  padding: var(--Button-paddingBlock, 0.375rem);
  opacity: 1;
  visibility: visible;
}

.home .home__button.--hide {
  height: 0;
  min-height: 0px;
  margin-top: 0px;
  padding: 0;
  opacity: 0;
  visibility: hidden;
}

.--loading-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 30%,
    rgba(255, 255, 255, 0.3),
    transparent 70%
  );
  background-size:
    300% 100%,
    cover;
  animation: slide 1s linear infinite;
}

.--loading-btn:disabled::after {
  background: linear-gradient(
    to right,
    transparent 30%,
    rgba(128, 128, 128, 0.15),
    transparent 70%
  );
  background-size:
    300% 100%,
    cover;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home__scores {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;

  &-header {
    &-button {
      @media (max-width: 400px) {
        padding-inline: 12px !important;
      }

      &.--active {
        background-color: var(--joy-palette-primary-600);
      }

      &.--spinning svg {
        animation: spin 0.5s infinite linear;
      }
    }

    &-divider {
      width: 2px;
      height: 1.5rem;
      margin: 0 6px;
      background-color: white;
    }
  }
}
