.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;
  box-shadow: var(--joy-shadow-md);
}

.navbar__content {
  height: 60px;
  width: min(1024px, 100%);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  gap: 12px;
  padding: 12px 16px;

  @media (max-width: 400px) {
    gap: 4px;
  }
}

.navbar__content-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-weight: bold;
}

.navbar__content-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  @media (max-width: 400px) {
    gap: 4px;
  }
}
