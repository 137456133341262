.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 16px;
  text-align: center;
  color: #888888;
  opacity: 0.5;

  > * {
    color: #888888;
    text-decoration: none;
  }
}

@media (max-height: 460px) {
  .footer {
    display: none;
  }
}
